import $ from 'jquery';

$(function () {
  //members
  $.get('./members/feed/rss2/', function (data) {
    $(data).find('item').each(function () {
      var el = $(this);
      var title = el.find('title').text();
      var description = el.find('description').text();
      var link = el.find('link').text();
      var category = el.find('category').text();

      var pubDate = el.find('pubDate').text();
      var timestamp = new Date(pubDate);
      var y = timestamp.getFullYear();
      var m = ('00' + (timestamp.getMonth() + 1)).slice(-2);
      var d = ('00' + timestamp.getDate()).slice(-2);
      var date = y + '.' + m + '.' + d;

      $('#js-rss-members').append(
        '<dl><dt>' + date + '</dt>' +
        '<dd><a href="' + link + '">' + title + '</a></dd></dl>'
      );
    });
  });

  //newsfeed
  $.get('./newsfeed/feed/rss2/', function (data) {
    $(data).find('item').each(function () {
      var el = $(this);
      var title = el.find('title').text();
      var description = el.find('description').text();
      var link = el.find('link').text();
      var category = el.find('category').text();

      var pubDate = el.find('pubDate').text();
      var timestamp = new Date(pubDate);
      var y = timestamp.getFullYear();
      var m = ('00' + (timestamp.getMonth() + 1)).slice(-2);
      var d = ('00' + timestamp.getDate()).slice(-2);
      var date = y + '.' + m + '.' + d;

      $('#js-rss-newsfeed').append(
        '<dl><dt>' + date + '</dt>' +
        '<dd><a href="' + link + '">' + title + '</a></dd></dl>'
      );
    });
  });
});
